export const menuItems = [
  {
    id: "home",
    title: "Home",
    route: "/",
  },
  {
    id: "about",
    title: "About",
    route: "/about",
  },
  {
    id: "services",
    title: "Services",
    route: "/services",
  },
  {
    id: "contactUs",
    title: "Contact Us",
    route: "/contactUs",
  },
];
